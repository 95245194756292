import React from 'react'
import Typography from '@material-ui/core/Typography'
import Container from '@material-ui/core/Container'
import Layout from '../components/Layout'
import SEO from '../components/SEO'

const NotFoundPage = () => (
  <Layout>
    <SEO title="Seite nicht gefunden" description="Seite nicht gefunden" />
    <Container maxWidth="md">
      <Typography variant="h3" component="h1">NICHT GEFUNDEN</Typography>
      <Typography variant="body2">Diese seite wurde nicht gefunden</Typography>
    </Container>
  </Layout>
)

export default NotFoundPage
